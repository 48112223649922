// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { Button } from '@stormideas/react-sdk';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { CheckboxField } from 'components/Form';
import ToolbarActions from './ToolbarActions';

import './TableToolbar.scss';
import FilteredTabs from './FilteredTabs/FilteredTabs';

class MTableToolbar extends React.Component {
  stopSubmit = e => {
    e.preventDefault();
  };

  renderSearch() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      // eslint-disable-next-line react/destructuring-assignment
      ...this.props.localization,
    };

    const { search, searchText, onSearchChanged } = this.props;

    if (search) {
      return (
        <Col md={6} lg={4}>
          <form className="form" onSubmit={this.stopSubmit}>
            <div className="form__form-group-field">
              <Label className="sr-only" htmlFor="tableSearch">
                Search
              </Label>
              <Input
                className="search-input"
                id="tableSearch"
                name="tableSearch"
                placeholder={localization.searchTooltip}
                type="search"
                value={searchText}
                onChange={event => onSearchChanged(event.target.value)}
              />
              <Button
                aria-label="Search"
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
          </form>
        </Col>
      );
    }
    return null;
  }

  renderDefaultActions() {
    const { actions } = this.props;
    return (
      <div>
        <this.props.components.ToolbarActions
          actions={actions && actions.filter(a => a.isFreeAction)}
        />
      </div>
    );
  }

  renderSelectedActions() {
    const { actions, selectedRows } = this.props;
    return (
      <React.Fragment>
        <this.props.components.ToolbarActions
          actions={actions.filter(a => !a.isFreeAction && a.title !== 'Edit')}
          data={selectedRows}
          selectedRows={selectedRows}
        />
      </React.Fragment>
    );
  }

  renderActions() {
    const { selectedRows } = this.props;
    return (
      <div>
        {selectedRows && selectedRows.length > 0
          ? this.renderSelectedActions()
          : this.renderDefaultActions()}
      </div>
    );
  }

  renderCustomFilters() {
    const { filters, updateFilters, customFilters } = this.props;
    const onValueChanged = (filterName, value) => {
      updateFilters({ [filterName]: value });
    };

    if (customFilters) return customFilters(filters, onValueChanged);

    return null;
  }

  renderSelectFilters() {
    const { filters, filterConfig, updateFilters } = this.props;
    const selectFilters = filterConfig.filter(conf => conf.type === 'select');
    return selectFilters.map(filter => (
      <Col xs="auto" className="labeled-toggle-container" key={filter.name}>
        <select
          className="form-control"
          name={`${filter.name}FilterSelect`}
          id={`${filter.name}FilterSelect`}
          onChange={e => updateFilters({ [filter.query]: e.target.value })}
          defaultValue={filters[filter.query] || filter.defaultValue}
        >
          {filter.displayName && (
            <option value="" disabled>
              {filter.displayName}
            </option>
          )}
          {filter.options.map(option => (
            <option value={option.value} key={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </Col>
    ));
  }

  renderCheckboxFilters() {
    const { filters, filterConfig, updateFilters } = this.props;
    const checkboxFilters = filterConfig.filter(
      conf => conf.type === 'checkbox'
    );
    return checkboxFilters.map(filter => (
      <Col xs="auto" key={filter.name}>
        <div className="custom-control custom-checkbox">
          <Input
            type="checkbox"
            className="custom-control-input"
            id={filter.name}
            name={filter.name}
            defaultChecked={filters[filter.query] || filter.defaultValue}
            onChange={e => {
              updateFilters({
                [filter.query]: e.target.checked,
              });
            }}
          />
          <Label htmlFor={filter.name} className="custom-control-label">
            Show active only
          </Label>
        </div>
      </Col>
    ));
  }

  render() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      // eslint-disable-next-line react/destructuring-assignment
      ...this.props.localization,
    };

    const {
      state,
      // children,
      updateStatusFilters,
      showStatusFilters,
      showUserFilter,
      updateUserFilter,
      userFilter,
      showActionToolbar,
      filterCounts,
      filterConfig,
      totalCount,
      updateFilters,
      activeFilter,
      customFilters,
    } = this.props;

    const tabs = showStatusFilters
      ? filterConfig.filter(x => x.type === 'tab')[0]
      : null;

    const pageSize = +(state.pageSize || 5);
    const start = pageSize * +(state.page || 0);
    let end = start + pageSize;
    if (end > totalCount) {
      end = totalCount;
    }

    const pagingSummary =
      totalCount > 0
        ? localization.pagingSummary
            .replace('{0}', start + 1)
            .replace('{1}', end)
            .replace('{2}', totalCount)
        : '';

    return (
      <>
        <Row className="table-toolbar">
          {this.renderSearch()}
          {pagingSummary && (
            <Col xs="auto">
              <p>{pagingSummary}</p>
            </Col>
          )}
          {showUserFilter && (
            <Col xs="auto">
              <Formik>
                <CheckboxField
                  label="Only show items assigned to me"
                  name="userFilter"
                  onChange={() => {
                    updateUserFilter(!userFilter);
                  }}
                  value={userFilter === true}
                />
              </Formik>
            </Col>
          )}
          {this.renderCustomFilters()}
          <Col xs={12} sm="auto" className="d-flex table-toolbar-button">
            {this.renderActions()}
          </Col>
          {this.renderSelectFilters()}
          {this.renderCheckboxFilters()}
        </Row>
        {tabs && (
          <Row className="table-toolbar align-items-center">
            <Col xs={12}>
              <FilteredTabs
                border="bottom"
                size="sm"
                className="form-actions fieldset"
                alignment="justify-content-between"
                activeFilter={activeFilter}
                content={tabs.options.map(groupedTab =>
                  groupedTab.map(x => ({
                    tabNameReadable: x.name,
                    tabName: x.value,
                    onChange: () => {
                      updateFilters({ state: x.value });
                    },
                    count: filterCounts.find(y => y.label === x.value)
                      ? filterCounts.find(y => y.label === x.value).value
                      : 0,
                  }))
                )}
              />
            </Col>
          </Row>
        )}
        {showActionToolbar && (
          <Row className="table-toolbar button-group">
            <Col className="d-flex flex-wrap">
              {this.renderSelectedActions()}
            </Col>
          </Row>
        )}
      </>
    );
  }
}

MTableToolbar.defaultProps = {
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.func])
  ),
  // children: '',
  localization: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export as CSV',
    searchTooltip: 'Search',
    pagingSummary: 'Showing {0} to {1} of {2}.',
  },
  search: true,
  showActionToolbar: false,
  showStatusFilters: false,
  showUserFilter: false,
  updateUserFilter: null,
  userFilter: false,
  searchText: '',
  selectedRows: [],
  components: {
    ToolbarActions,
  },
  filterConfig: [],
};

MTableToolbar.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.func])
  ),
  // children: PropTypes.node,
  localization: PropTypes.shape({}),
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.bool,
  searchText: PropTypes.string,
  showActionToolbar: PropTypes.bool,
  showStatusFilters: PropTypes.bool,
  showUserFilter: PropTypes.bool,
  updateUserFilter: PropTypes.func,
  userFilter: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  state: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    pageSize: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  components: PropTypes.shape({}),
  updateStatusFilters: PropTypes.func.isRequired,
  filterCounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUser: PropTypes.shape({ id: PropTypes.string }).isRequired,
  filterConfig: PropTypes.arrayOf(PropTypes.shape({})),
  updateFilters: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default MTableToolbar;
