import { createApiReducer } from 'infrastructure/api';

export const initialState = { currentUser: {} };

const handlers = {
  'app/user/GET_USER_INFO': (state, payload) => payload || null,
};

const reducer = createApiReducer(handlers, initialState);

export default reducer;
