import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FieldArray, getIn } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripVertical } from '@fortawesome/pro-regular-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from '@stormideas/react-sdk';
import SingleFieldItems from './SingleFieldItems';
import MultipleFieldItems from './MultipleFieldItems';

import './ListField.scss';

/** Used for wrapping sections of a form that can be added multiple times */

const componentWrapper = ({ sortable }, Component) => {
  if (!sortable) return Component;

  // eslint-disable-next-line react/prop-types
  return ({ index, ...props }) => (
    <Draggable key={index} draggableId={`${index}`} index={index}>
      {(p, s) => (
        <div
          className={classnames('list-group-item', {
            active: s.isDragging,
          })}
          ref={p.innerRef}
          {...p.draggableProps}
          {...p.dragHandleProps}
        >
          <FontAwesomeIcon icon={faGripVertical} className="icon drag-handle" />
          <Component index={index} {...props} />
        </div>
      )}
    </Draggable>
  );
};

const onDragEnd = swap => result => {
  if (!result.destination) return;

  swap(result.source.index, result.destination.index);
};

const Wrapper = ({ sortable, children, swap }) => {
  const wrapper = <div className="list-field">{children}</div>;

  if (sortable) {
    return (
      <DragDropContext onDragEnd={onDragEnd(swap)}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef} className="dnd">
              {wrapper}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return wrapper;
};

Wrapper.propTypes = {
  sortable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Wrapper.defaultProps = {
  sortable: false,
};

function ListField({
  addButtonCopy,
  component,
  label,
  name,
  singleField,
  ...props
}) {
  const Component = componentWrapper(props, component);
  return (
    <FieldArray
      name={name}
      render={({ form, push, remove, ...rest }) => {
        const values = getIn(form.values, name, []);

        return (
          <Wrapper {...props} {...rest}>
            <SingleFieldItems
              visible={singleField}
              Component={Component}
              name={name}
              values={values}
              remove={remove}
              {...props}
            />
            <MultipleFieldItems
              visible={!singleField}
              Component={Component}
              name={name}
              values={values}
              remove={remove}
              {...props}
            />
            <div className="d-flex justify-content-end">
              <Button
                className="btn-add-field"
                color="primary"
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => {
                  push({});
                  document.activeElement.blur();
                }}
              >
                {addButtonCopy}
              </Button>
            </div>
          </Wrapper>
        );
      }}
      {...props}
    />
  );
}

ListField.propTypes = {
  /** Change the add button text */
  addButtonCopy: PropTypes.string,
  /** Children form nodes */
  component: PropTypes.func.isRequired,
  /** Unsure what this is used for, possibly group name? */
  lineItem: PropTypes.string,
  /** Input name, will be appended with a number */
  name: PropTypes.string.isRequired,
  /** Component only has one input */
  singleField: PropTypes.bool,
  /** Can be re-ordered */
  sortable: PropTypes.bool,
  label: PropTypes.string,
};

ListField.defaultProps = {
  addButtonCopy: 'Add',
  lineItem: 'Item',
  singleField: false,
  sortable: false,
  label: '',
};

export default ListField;
