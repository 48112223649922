import { createReducer } from 'redux-act';
import {
  SET_STORY_STATE,
  SET_PAGE_STATES,
  SET_PAGE_PROCESSED,
} from './actions';

const initialState = {
  storyState: 'Saved',
  pageStates: [],
};

export default createReducer(
  {
    [SET_STORY_STATE]: (state, payload) => ({
      ...state,
      storyState: payload,
    }),
    [SET_PAGE_STATES]: (state, payload) => ({
      ...state,
      pageStates: payload,
    }),
    [SET_PAGE_PROCESSED]: (state, payload) => {
      const { pageStates } = state;
      return {
        ...state,
        pageStates: [...pageStates.filter(p => p.id !== payload.id), payload],
      };
    },
  },
  initialState
);
