import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Modal, Button } from '@stormideas/react-sdk';

const UnscheduleModal = ({
  title,
  onUnschedule,
  onCancel,
  isPublished,
  entityName,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <Modal
      hideButton
      show
      color="danger"
      confirmationModal
      size="sm"
      footer={
        <Row>
          <Col xs="auto">
            <Button
              color="dark"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={onCancel}
              outline
              disabled={isRemoving}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              autofocus="true"
              color="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => {
                setIsRemoving(true);
                onUnschedule();
              }}
              loading={isRemoving}
            >
              Yes, Remove
            </Button>
          </Col>
        </Row>
      }
    >
      <h3>
        {isPublished
          ? `${title} is live.
      This will immediately remove the ${entityName} from the app.`
          : `Are you sure you want to remove the schedule from ${title}?`}
      </h3>
    </Modal>
  );
};

UnscheduleModal.propTypes = {
  title: PropTypes.string.isRequired,
  onUnschedule: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
};

export default UnscheduleModal;
