import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import renderCheckBoxField from './CheckBox';

import './CheckboxField.scss';

/** Custom styled checkbox  */

const CheckboxField = props => {
  const { className, label, name, onChange, validate } = props;
  return (
    <Field
      {...props}
      type="checkbox"
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
      render={({ field, ...p }) =>
        renderCheckBoxField({
          className,
          input: field,
          label,
          onChange,
          ...p,
        })
      }
    />
  );
};

CheckboxField.propTypes = {
  /** Additional custom style class */
  className: PropTypes.string,
  /** Label */
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string,
  /** onChange function handler */
  onChange: PropTypes.func,
  /** Validation Array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
};

CheckboxField.defaultProps = {
  className: 'colored-click',
  label: '',
  name: '',
  onChange: null,
  validate: [],
};

export default CheckboxField;
