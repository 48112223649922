export const validateStartTime = (value, formValues) => {
  let error;
  if (formValues.publishAtDate && !value) {
    error = 'Please enter a Start Time.';
  }
  return error;
};
export const validateEndTime = (value, formValues) => {
  let error;
  if (formValues.removeAtDate && !value) {
    error = 'Please enter an End Time.';
  }
  return error;
};
