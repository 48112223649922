import React from 'react';
import PropTypes from 'prop-types';
import { Field, connect } from 'formik';
import { startCase, get } from 'lodash';
import { Col, FormFeedback, Row } from 'reactstrap';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { processValidate } from 'infrastructure/form';
import { SelectField } from 'components/Form';

import './DateTimeField.scss';

/** Field for Date and/or Time */

const processValue = (value, zone) =>
  value && (moment.isMoment(value) ? value : moment.tz(value, zone).utc());

const renderDatePickerField = props => <DateTime {...props} />;

const DateTimeField = props => {
  const {
    formik,
    label,
    name,
    onChange,
    type,
    validate,
    withTimezone,
    timezones,
    defaultTimezone,
  } = props;

  const zone = get(formik.values, `${name}Timezone`) || defaultTimezone;

  const placeholderOptions = {
    date: 'Date',
    time: 'Time',
    datetime: 'Date and Time',
  };

  const formatOptions = {
    date: 'YYYY/MM/DD',
    datetime: 'YYYY/MM/DD',
    time: 'h:mm A',
  };

  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = error && touched;
  const isValid = touched && !error;

  const updateValue = form => value => {
    if (moment.isMoment(value)) {
      form.setFieldValue(name, value);
      form.setFieldTouched(name, true);
      if (onChange) {
        onChange(value);
      }
    }
  };

  return (
    <div className="form__form-group-input-wrap--error-above datetime-field">
      <Row className="datetime-row">
        <Col className="datetime-col">
          <Field
            {...props}
            type="date"
            render={({ form, field, ...p }) =>
              renderDatePickerField({
                closeOnSelect: type === 'date',
                ...p,
                ...props,
                ...field,
                value: processValue(field.value, zone),
                zone,
                onChange: updateValue(form),
                onBlur: updateValue(form),
                name,
                dateFormat: type !== 'time' && formatOptions[type],
                timeFormat:
                  (type === 'time' || type === 'datetime') &&
                  formatOptions.time,
                displayTimeZone: zone,
                inputProps: {
                  placeholder: placeholderOptions[type],
                  className: classnames({
                    'is-valid': isValid,
                    'is-invalid': showError,
                  }),
                },
              })
            }
            validate={processValidate(
              label || startCase(name),
              validate,
              props
            )}
          />
        </Col>
        {withTimezone && type !== 'date' && (
          <Col sm="auto" className="datetime-col timezone-col">
            <SelectField
              name={`${name}Timezone`}
              options={[
                {
                  label: 'Local',
                  value: moment.tz.guess(),
                },
                ...timezones,
              ]}
              defaultValue={zone}
            />
          </Col>
        )}
      </Row>
      {showError && (
        <FormFeedback className="validation form__form-group-error" tag="small">
          {error}
        </FormFeedback>
      )}
    </div>
  );
};

DateTimeField.propTypes = {
  /** Formik Props */
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  label: PropTypes.string,
  /** Pick one date or multiple */
  mode: PropTypes.oneOf(['single', 'multiple']),
  /** First selectable date */
  minDate: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string,
  /** Desired inputs */
  type: PropTypes.oneOf(['date', 'datetime', 'time']),
  /** Validation Array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  /** Show timezone selector */
  withTimezone: PropTypes.bool,
  /** Default timezone */
  defaultTimezone: PropTypes.string,
  /** Timezones to choose from */
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
};

DateTimeField.defaultProps = {
  label: '',
  mode: 'single',
  minDate: '',
  name: '',
  type: 'datetime',
  validate: [],
  withTimezone: false,
  defaultTimezone: 'America/New_York',
  timezones: [
    { label: 'UTC', value: 'utc' },
    { label: 'EST', value: 'America/New_York' },
    { label: 'CST', value: 'America/Chicago' },
    { label: 'MST', value: 'America/Denver' },
    { label: 'PST', value: 'America/Los_Angeles' },
  ],
  onChange: null,
};

export default connect(DateTimeField);
