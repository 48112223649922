import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Modal, Button } from '@stormideas/react-sdk';

const DeleteModal = ({
  title,
  onDelete,
  onCancel,
  isPublished,
  entityName,
  deleteMessage,
  hideCloseButton,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const message = isPublished
    ? `${title} is live.
      This will immediately remove the ${entityName} from the app.`
    : `Are you sure you want to delete ${title}?`;
  return (
    <Modal
      hideButton
      show
      color="danger"
      confirmationModal
      size="sm"
      hideCloseButton={hideCloseButton}
      footer={
        <Row>
          <Col xs="auto">
            <Button
              color="dark"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={onCancel}
              outline
              disabled={isDeleting}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              autofocus="true"
              color="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => {
                setIsDeleting(true);
                onDelete();
              }}
              loading={isDeleting}
            >
              Yes, Delete
            </Button>
          </Col>
        </Row>
      }
    >
      <h3>{deleteMessage || message}</h3>
    </Modal>
  );
};

DeleteModal.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  isPublished: PropTypes.bool,
  entityName: PropTypes.string,
  deleteMessage: PropTypes.string,
  hideCloseButton: PropTypes.bool,
};

DeleteModal.defaultProps = {
  title: null,
  onDelete: null,
  onCancel: null,
  isPublished: false,
  entityName: null,
  deleteMessage: null,
  hideCloseButton: false,
};

export default DeleteModal;
