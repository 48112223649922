import { createApiReducer, states } from 'infrastructure/api';

export const initialState = {};

const update = (state, payload) => ({
  ...state,
  ...payload,
  authenticated: true,
  status: states.loaded,
});

const handlers = {
  'app/auth/GET_LOGIN_METHODS': (state, payload) => ({
    ...state,
    loginMethods: payload,
  }),
  'app/auth/LOGIN': update,
  'app/auth/EXTERNAL_PROVIDER_LOGIN': update,
  'app/auth/REFRESH': update,
  'app/auth/LOGOUT': () => ({}),
};

const apiReducer = createApiReducer(handlers, initialState);

const accountReducer = (state = initialState, action) => {
  if (action.type === 'CLEAR_AUTH') {
    return { loginMethods: state.loginMethods };
  }

  return apiReducer(state, action);
};

export default accountReducer;
