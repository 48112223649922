import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';

import { selectTimezone } from './selectors';

const mapStateToProps = createStructuredSelector({
  timezone: selectTimezone(),
});

const enhance = compose(connect(mapStateToProps));

export default enhance;
