import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import { withApi } from 'infrastructure/api';
import injectReducer from 'infrastructure/redux/injectReducer';

import reducer from './reducer';
import { makeSelectMenu, makeSelectCurrentPath } from './selectors';

const mapStateToProps = createStructuredSelector({
  menu: makeSelectMenu(),
  path: makeSelectCurrentPath(),
});

function mapDispatchToProps(dispatch, props) {
  return {
    api: bindActionCreators(props.api.system.menu, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'menu', reducer });

const enhance = compose(
  withRouter,
  withApi,
  withReducer,
  withConnect,
  lifecycle({
    componentDidMount() {
      this.props.api.get();
    },
  })
);

export default enhance;
