import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import TextEditorBase from './TextEditorBase';

import './TextEditor.scss';

const renderTextEditor = ({ field, form, onChange, ...props }) => (
  <TextEditorBase
    {...props}
    initialValue={field.value}
    onChange={value => {
      form.setFieldValue(field.name, value);
      if (onChange) {
        onChange(value);
      }
    }}
  />
);

renderTextEditor.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
};

renderTextEditor.defaultProps = {
  onChange: null,
};

/** Rich Markdown text editor<br />
 * [See this comment for toolbar options.](https://github.com/jpuri/react-draft-wysiwyg/issues/4#issuecomment-256276114)
 */

const TextEditor = props => {
  const { validate, label, name, toolbarOptions } = props;

  return (
    <Field
      {...props}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
      render={p =>
        renderTextEditor({
          ...props,
          ...p,
          toolbarOptions,
        })
      }
    />
  );
};

TextEditor.propTypes = {
  /** Validation array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  /** Form row label */
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string,
  /** Toolbar options */
  toolbarOptions: PropTypes.shape({}),
  /** onChange functon handler */
  onChange: PropTypes.func,
};

TextEditor.defaultProps = {
  validate: [],
  label: '',
  name: '',
  toolbarOptions: {
    options: ['blockType', 'inline', 'list', 'textAlign', 'link'],
    list: {
      options: ['unordered', 'ordered'],
    },
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
  },
  onChange: null,
};

export default TextEditor;
