import React from 'react';
import { loading } from 'infrastructure/enhancers';
import SelectField from '../SelectField';

/**
 * A description of the component and when it should be used.
 */

export default loading(
  ({ options }) => !options || !options.length,
  ({ name, defaultValue }) => (
    <SelectField
      isDisabled
      placeholder="Loading..."
      defaultValue={defaultValue}
      name={name}
    />
  )
)(props => <SelectField {...props} />);
