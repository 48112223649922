import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faVideo,
  faFile,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import { Img, ProgressBar, Button } from '@stormideas/react-sdk';

const DropZoneField = props => {
  const {
    accept,
    className,
    circular,
    emptyMessage,
    fieldProps,
    maxSize,
    multiple,
    name,
    onChange,
    // value,
    customRemoveFile,
    onDropRejected,
    uploads,
  } = props;

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: acceptedFiles => {
      setFiles(
        files.concat(
          acceptedFiles.map(file => {
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
            return file;
          })
        )
      );
      onChange(acceptedFiles);
    },
    onDropRejected,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const removeFile = (file, e) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
    e.stopPropagation();
  };

  const previewContents = (file, upload) => {
    switch (true) {
      case file.type.startsWith('image'):
        return (
          <Img
            blurFill
            lightenBackground
            src={file.preview}
            alt={file.name}
            className="file-thumbnail"
          />
        );
      case file.type.startsWith('video') &&
        upload &&
        upload.status &&
        upload.status === 'uploading':
        return (
          <div className="dropzone-spinner">
            <FontAwesomeIcon icon={faSpinner} />
          </div>
        );
      case file.type.startsWith('video'):
        return <FontAwesomeIcon icon={faVideo} className="placeholder-icon" />;
      default:
        return <FontAwesomeIcon icon={faFile} className="placeholder-icon" />;
    }
  };

  return (
    <div
      {...getRootProps({
        className: classnames('dropzone form-control', className),
      })}
      {...fieldProps}
    >
      <input
        {...getInputProps()}
        className="dropzone-input"
        id={name}
        maxsize={maxSize} // 64MB
        multiple={multiple}
        name={name}
      />
      {(!files || files.length === 0) && (
        <div className="dropzone-drop-here">{emptyMessage}</div>
      )}

      {files && Array.isArray(files) && files.length > 0 && (
        <div className="files-wrapper">
          {files.map((file, i) => {
            const upload = uploads.find(x => x.id === file.preview);
            return (
              <>
                <div
                  className={classnames('file-preview', {
                    'rounded-circle': circular,
                  })}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                >
                  {previewContents(file, upload)}
                  <small className="file-name">{file.name}</small>
                  <Button
                    className="remove rounded-circle"
                    color="danger"
                    icon={<FontAwesomeIcon icon={faMinusCircle} />}
                    onClick={e => {
                      removeFile(i, e);
                      if (customRemoveFile) {
                        customRemoveFile(file, e);
                      }
                    }}
                    size="sm"
                  />
                  {upload &&
                    upload.status !== 'uploaded' &&
                    upload.status !== 'failed' && (
                      <ProgressBar
                        color="info"
                        size="sm"
                        value={upload.bytesUploaded}
                        total={upload.size}
                      />
                    )}
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

DropZoneField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  circular: PropTypes.bool,
  emptyMessage: PropTypes.node.isRequired,
  fieldProps: PropTypes.shape({}),
  multiple: PropTypes.bool.isRequired,
  maxSize: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  ]),
  customRemoveFile: PropTypes.func,
  onDropRejected: PropTypes.func,
  uploads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DropZoneField.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  className: '',
  circular: false,
  fieldProps: null,
  onChange: null,
  maxSize: 64000000,
  value: '',
  customRemoveFile: null,
  onDropRejected: null,
};

const renderDropZoneField = props => {
  const {
    accept,
    className,
    circular,
    emptyMessage,
    fieldProps,
    input,
    maxSize,
    multiple,
    customRemoveFile,
    onDropRejected,
    uploads,
  } = props;
  return (
    <DropZoneField
      {...input}
      accept={accept}
      className={className}
      circular={circular}
      emptyMessage={emptyMessage}
      fieldProps={fieldProps}
      maxSize={maxSize}
      multiple={multiple}
      customRemoveFile={customRemoveFile}
      onDropRejected={onDropRejected}
      uploads={uploads}
    />
  );
};

renderDropZoneField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  circular: PropTypes.bool,
  className: PropTypes.string,
  emptyMessage: PropTypes.node.isRequired,
  fieldProps: PropTypes.shape({}),
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  customRemoveFile: PropTypes.func,
  onDropRejected: PropTypes.func,
  uploads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

renderDropZoneField.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  className: '',
  circular: false,
  fieldProps: null,
  maxSize: 64000000,
  multiple: false,
  customRemoveFile: null,
  onDropRejected: null,
};

export default renderDropZoneField;
