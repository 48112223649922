import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import renderRadioButtonField from './RadioButton';

import './RadioButtonField.scss';

/** Custom styled radio button */

const RadioButtonField = props => {
  const { validate, label, name, radioValue } = props;
  return (
    <Field
      {...props}
      type="radio"
      name={name}
      label={label}
      validate={processValidate(label || startCase(name), validate, props)}
      render={({ form, field, ...p }) =>
        renderRadioButtonField({
          input: {
            ...field,
            onChange: value => form.setFieldValue(name, value),
            value: form.values[name] || '',
          },
          label,
          radioValue,
          ...p,
        })
      }
    />
  );
};
RadioButtonField.propTypes = {
  /** Field label */
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string,
  /** Validation array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  radioValue: PropTypes.string,
};
RadioButtonField.defaultProps = {
  label: '',
  name: '',
  validate: [],
  radioValue: '',
};

export default RadioButtonField;
