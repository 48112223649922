import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class MainWrapper extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({
      className: PropTypes.string,
    }).isRequired,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { theme, children, className } = this.props;

    return (
      <div className={classnames(theme.className, className)}>
        <div className="wrapper">{children}</div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.layout.theme,
}))(MainWrapper);
