import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SocialModerationSheet from 'slices/SocialModerationSheet/Loadable';
import SocialMediaAccount from 'slices/SocialMediaAccount/Loadable';
import SocialModerationCategory from 'slices/SocialModerationCategory/Loadable';
import SocialModerationMediaItem from 'slices/SocialModerationMediaItem/Loadable';

export default () => (
  <Switch>
    <Route path="/social-moderation/import" component={SocialModerationSheet} />
    <Route
      path="/social-moderation/social-media-accounts"
      component={SocialMediaAccount}
    />
    <Route
      path="/social-moderation/media"
      component={SocialModerationMediaItem}
    />
    <Route
      path="/social-moderation/categories"
      component={SocialModerationCategory}
    />
    <Route path="*">
      <Redirect to="/social-moderation/import" />
    </Route>
  </Switch>
);
