import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { visibility } from 'infrastructure/enhancers';

/**
 * Only really useful in `<Content />` - otherwise a `<p>` tag with classes is better.
 * Also best to handle conditional rendering with && operator and check if data for the child notes exists.
 */

const SubHeading = ({ subHeading, children }) => (
  <p className="page-subhead subhead">{subHeading || children}</p>
);

SubHeading.propTypes = {
  subHeading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SubHeading.defaultProps = {
  subHeading: null,
  children: null,
};

const enhance = compose(
  withProps(props => ({
    visible:
      ((props.subHeading || props.children) &&
        (props.visible === undefined || props.visible === null)) ||
      props.visible,
  })),
  visibility,
  React.memo
);

export default enhance(SubHeading);
