// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Content } from 'components';

const PrivacyPolicy = () => (
  <Content title="Privay Policy">
    <div>
      <p>
        <span>
          Thank you for visiting an online service that posts a link to this
          Privacy Policy, owned or operated by StormKit. This Privacy Policy
          will provide you with information as to how StormKit collects, uses,
          and shares information about you, including the choices StormKit
          offers with respect to that information, and applies to your use of
          any service, regardless of how you access or use it.
        </span>
      </p>

      <p>
        <span>
          By visiting or otherwise using the service, you consent to StormKit's
          collection, use, and disclosure practices, and other activities as
          described in this Privacy Policy. If you do not agree and consent,
          please discontinue use of the service.
        </span>
      </p>

      <p>
        <span>
          StormKit is committed to ensuring that your privacy is protected.
          Should we ask you to provide certain information by which you can be
          identified when using this service, then you can be assured that it
          will only be used in accordance with this policy.
        </span>
      </p>

      <p>
        <span>
          StormKit may change this policy from time to time by updating this
          page. You should check this page from time to time to ensure that you
          are happy with any changes. This policy is effective from 30 June
          2021.
        </span>
      </p>

      <h1>
        <span>What we may collect and what we do with this information</span>
      </h1>

      <h2>
        <span>Contact Information</span>
      </h2>

      <p>
        <span>When: When we create an account for you.</span>
      </p>

      <p>
        <span>What: Your name and email address.</span>
      </p>

      <p>
        <span>Why: To allow you to login to use the service.</span>
      </p>

      <h2>
        <span>Page Information</span>
      </h2>

      <p>
        <span>When: When you connect your Facebook page to StormKit.</span>
      </p>

      <p>
        <span>
          What: Your pages public information, including posts and comments on
          those posts
        </span>
      </p>

      <p>
        <span>
          Why: To allow us to see your posts comments so that any images can be
          reviewed and removed if necessary
        </span>
      </p>

      <h2>
        <span>
          Information that your browser automatically sends whenever you visit a
          website
        </span>
      </h2>

      <p>
        <span>When: When you visit this service.</span>
      </p>

      <p>
        <span>
          What: This data may include information such as your IP address,
          browser type or the domain from which you are visiting, the web-pages
          you visit, the search terms you use, the location of your ISP and any
          advertisements on which you click.
        </span>
      </p>

      <p>
        <span>Why:</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>To monitor the use of this service.</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>For this service's technical administration.</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          NOT to associate your IP address with any other personally
          identifiable information to identify you personally.
        </span>
      </p>

      <p>
        <span>
          We may disclose any of the above information for special cases where
          we have reason to believe that doing so is necessary to identify,
          contact or bring legal action against someone who may be causing
          injury or interference with our rights, property, our customers or
          anyone who could be harmed by such activities. We may also disclose
          information we collect when we believe, in good faith, that the law
          requires it.
        </span>
      </p>

      <h1>
        <span>Security</span>
      </h1>

      <p>
        <span>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </span>
      </p>

      <p>
        <span>
          The data we collect is stored with an industry leading cloud provider
          within the US. All of our data is encrypted while it is at rest.
        </span>
      </p>

      <p>
        <span>Additionally:</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          For data in transit, industry-standard transport protocols between
          user devices and our data centers are used. These same protocols are
          used within the data centers themselves.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Detection and mitigation techniques are used to protect against DDoS
          attacks.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>Servers have antimalware software installed.</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>Regular penetration testing is conducted.</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Our cloud provider has a global incident response team which works
          around the clock to mitigate the effects of any attacks. These are
          backed by centers of excellence that fight digital crime, respond to
          security incidents and vulnerabilities, and combat malware.
        </span>
      </p>

      <h1>
        <span>How we use cookies</span>
      </h1>

      <p>
        <span>
          Cookies are small pieces of information sent by a web server to a web
          browser which allows the server to uniquely identify the browser on
          each page.
        </span>
      </p>

      <p>
        <span>
          StormKit makes use of Facebook and Twitter login, who store cookies
          that are required for us to connect to your Facebook page or Twitter
          profile. Both Facebook and Twitter have their own cookie policies
          which can be viewed at https://www.facebook.com/policies/cookies/ and
          at https://twitter.com/privacy respectively.
        </span>
      </p>

      <p>
        <span>
          A cookie in no way gives us access to your computer or any information
          about you, other than the data you choose to share with us.
        </span>
      </p>

      <p>
        <span>
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the service.
        </span>
      </p>

      <h1>
        <span>Controlling your personal information</span>
      </h1>

      <p>
        <span>Please contact michael@stormideas.com if:</span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You wish to request details of personal information which we hold
          about you.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You wish for us to restrict the collection, processing or use of your
          personal information.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You wish for us to stop the collection, processing or use of your
          personal information.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You wish for us to destroy any record of your personal information.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You wish for us to transfer your personal information elsewhere.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          You believe any information we are holding about you is incorrect or
          incomplete.
        </span>
      </p>

      <p>
        <span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>We will respond to your query within 30 days.</span>
      </p>
    </div>
  </Content>
);

export default PrivacyPolicy;
