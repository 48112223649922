import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@stormideas/react-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TableActions extends React.Component {
  renderButton(action, index) {
    const { data } = this.props;

    if (typeof action === 'function') {
      // eslint-disable-next-line no-param-reassign
      action = action(data);
      if (!action) {
        return null;
      }
    }

    return (
      <Button
        aria-label={action.title}
        buttonStyle={action.buttonStyle}
        color={action.color || 'info'}
        disabled={action.disabled}
        key={`${action.icon} ${index}`}
        onClick={event => {
          if (action.onClick) {
            action.onClick(event, data);
            event.stopPropagation();
          }
        }}
        size="sm"
        title={action.title}
        iconAfter={action.iconAfter}
        icon={<FontAwesomeIcon icon={action.icon} />}
      >
        {!action.iconOnly && action.tooltip}
      </Button>
    );
  }

  render() {
    const { actions } = this.props;
    if (actions) {
      return actions.map((action, index) => this.renderButton(action, index));
    }

    return null;
  }
}

TableActions.defaultProps = {
  actions: [],
  data: {},
};

TableActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ),
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default TableActions;
