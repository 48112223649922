import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { Secured } from 'infrastructure/security';
import { ErrorBoundary, ErrorPage, NotFound } from 'infrastructure/error';
import { withCurrentUser } from 'infrastructure/currentUser';

import { compose } from 'recompose';

import Dashboard from 'containers/Dashboard';
import Layout from 'containers/Layout';
import LogIn, { ForgotPassword, ResetPassword } from 'slices/Account/Loadable';
import MainWrapper from 'containers/App/MainWrapper';
import User from 'slices/User/Loadable';
import Tenant from 'slices/Tenant/Loadable';
import TikTokCampaign from 'slices/TikTokCampaign/Loadable';
import CaptionRequest from 'slices/CaptionRequest/Loadable';
import PublicTikTokCampaign from 'slices/PublicTikTokCampaign/Loadable';
import PublicInstagramArtist from 'slices/PublicInstagramArtist/Loadable';
import PublicInstagramRelease from 'slices/PublicInstagramRelease/Loadable';
import PublicInstagramNarrative from 'slices/PublicInstagramNarrative/Loadable';
import SocialModerationRoutes from 'slices/SocialModeration/Routes';
import { connect } from 'react-redux';
import PrivacyPolicy from 'legal/PrivacyPolicy';
import Agency from 'slices/Agency/Loadable';
import Artist from 'slices/Artist/Loadable';
import Narrative from 'slices/Narrative/Loadable';
import Release from 'slices/IGRelease/Loadable';

const enhance = compose(
  withCurrentUser,
  withRouter,
  connect(({ account }) => ({
    account,
  }))
);

const showLayout = path =>
  !(path.includes('stories') && path.includes('preview'));

const wrappedRoutes = enhance(props => {
  const {
    location,
    currentUser: { isSystemAdmin },
    account: { tenant_id: tenantId },
  } = props;
  const background = location && location.state && location.state.background;
  const layout = location ? showLayout(location.pathname) : true;

  return (
    <Secured>
      <React.Fragment>
        {layout && <Layout />}
        <div className={layout && 'container__wrap'}>
          {(!isSystemAdmin || tenantId) && (
            <ErrorBoundary>
              <Switch location={background || location}>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/user" component={User} />
                <Route path="/caption-requests" component={CaptionRequest} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/tik-tok-campaign" component={TikTokCampaign} />
                <Route path="/instagram-reporting/artist" component={Artist} />
                <Route path="/instagram-reporting/agency" component={Agency} />
                <Route
                  path="/instagram-reporting/narrative"
                  component={Narrative}
                />
                <Route
                  path="/instagram-reporting/release"
                  component={Release}
                />
                <Route
                  path="/social-moderation"
                  component={SocialModerationRoutes}
                />
                <Route path="*">
                  <Redirect to="/dashboard" />
                </Route>
              </Switch>
            </ErrorBoundary>
          )}
          {isSystemAdmin && !tenantId && (
            <ErrorBoundary>
              <Switch location={background || location}>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/user" component={User} />
                <Route path="/tenant" component={Tenant} />
                <Route path="*">
                  <Redirect to="/tenant" />
                </Route>
              </Switch>
            </ErrorBoundary>
          )}
        </div>
      </React.Fragment>
    </Secured>
  );
});

wrappedRoutes.propTypes = {
  location: PropTypes.shape({}),
};

wrappedRoutes.defaultProps = {
  location: null,
};

const AppRouter = props => {
  const { className } = props;
  return (
    <MainWrapper className={className}>
      <main>
        <Switch>
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/campaign/:id" component={PublicTikTokCampaign} />
          <Route path="/ig-dashboard/:id" component={PublicInstagramArtist} />
          <Route
            path="/ig-dashboard-release/:id"
            component={PublicInstagramRelease}
          />
          <Route
            path="/ig-dashboard-narrative/:id"
            component={PublicInstagramNarrative}
          />
          <Route path="/error" component={ErrorPage} />
          <Route path="/500" component={ErrorPage} />
          <Route path="/404" component={NotFound} />
          <Route path="/" component={wrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

AppRouter.propTypes = {
  className: PropTypes.string,
};

AppRouter.defaultProps = {
  className: '',
};

export default AppRouter;
