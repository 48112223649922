import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { visibility } from 'infrastructure/enhancers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretUp,
  faCaretDown,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'reactstrap';

import { withCurrentUser } from 'infrastructure/currentUser';
import TableToolbar from './TableToolbar';
import DataTablePagination from './Pagination';
import TableActions from './TableActions';
import {
  changeSort,
  changePage,
  changeRowsPerPage,
  changeFilters,
  changeSearch,
  changeUserFilter,
} from './actions';
import filterActions from './filterActions';

import './DataTable.scss';

const handleFetchData = (
  onFetchData = () => {},
  page = 0,
  pageSize = 20,
  sorted,
  filters,
  searchText
) => {
  const skipCount = page * pageSize;
  const maxResultCount = pageSize;
  const sorting =
    sorted && sorted.length
      ? `${sorted[0].id}${sorted[0].desc ? ' desc' : ''}`
      : '';

  onFetchData({
    skipCount,
    maxResultCount,
    sorting,
    searchText,
    ...filters,
  });
};

const selectTableMetaData = (state, { name }) => state.dataTable[name] || {};
const enhance = compose(
  withCurrentUser,
  withProps(({ visible }) => ({
    visible: ((visible === undefined || visible === null) && true) || visible,
  })),
  visibility,
  connect(
    (state, props) => {
      const metaData = selectTableMetaData(state, props);

      return {
        metaData,
        formatExtraData: {
          ...props.formatExtraData,
        },
      };
    },
    (dispatch, props) => ({
      storePage: page => {
        dispatch(
          changePage({
            name: props.name,
            page,
          })
        );
        if (typeof props.onPageChange === 'function') props.onPageChange();
      },
      storePageSize: pageSize => {
        dispatch(
          changeRowsPerPage({
            name: props.name,
            pageSize,
          })
        );
        if (typeof props.onPageSizeChange === 'function')
          props.onPageSizeChange();
      },
      storeSorted: sorted => {
        dispatch(
          changeSort({
            name: props.name,
            sorted,
          })
        );
        if (typeof props.onSortedChange === 'function') props.onSortedChange();
      },
      storeFilters: filters => {
        dispatch(
          changeFilters({
            name: props.name,
            filters,
          })
        );
      },
      storeUserFilter: userFilter => {
        dispatch(
          changeUserFilter({
            name: props.name,
            userFilter,
          })
        );
      },
      storeSearch: searchText => {
        dispatch(
          changeSearch({
            name: props.name,
            searchText,
          })
        );
      },
    })
  ),
  withState('searchText', 'onSearchChanged'),
  withState('searchTimeout', 'setSearchTimeout'),
  withState('filters', 'setFilters', {}),
  withState('selected', 'setSelected', []),
  withHandlers({
    isSelected: props => id => props.selected.indexOf(id) > -1,
    onSearchChanged: props => searchText => {
      if (props.onFetchData) {
        const {
          metaData: { pageSize, sorted, filters },
        } = props;

        clearTimeout(props.searchTimeout);

        const searchTimeout = setTimeout(() => {
          handleFetchData(
            props.onFetchData,
            0,
            pageSize,
            sorted,
            filters,
            searchText
          );
        }, 1000);
        props.setSearchTimeout(searchTimeout);
      }
      props.storeSearch(searchText);
      props.storePage(0);
    },
    onPageChange: props => page => {
      props.storePage(page);
      const meta = props.metaData;
      handleFetchData(
        props.onFetchData,
        page,
        meta.pageSize,
        meta.sorted,
        meta.filters,
        meta.searchText
      );
    },
    onPageSizeChange: props => pageSize => {
      props.storePageSize(pageSize);
      const meta = props.metaData;
      props.storePage(0);
      handleFetchData(
        props.onFetchData,
        0,
        pageSize,
        meta.sorted,
        meta.filters,
        meta.searchText
      );
    },
    onSortedChange: props => sorted => {
      props.storeSorted(sorted);
      const meta = props.metaData;
      props.storePage(0);
      handleFetchData(
        props.onFetchData,
        0,
        meta.pageSize,
        sorted,
        meta.filters,
        meta.searchText
      );
    },
    updateFiltersHandler: props => filter => {
      const meta = props.metaData;
      const newFilters = { ...meta.filters };
      newFilters[Object.keys(filter)[0]] = filter[Object.keys(filter)[0]];
      props.storeFilters(newFilters);
      props.storePage(0);
      handleFetchData(
        props.onFetchData,
        0,
        meta.pageSize,
        meta.sorted,
        newFilters,
        meta.searchText
      );
    },
    updateUserFilterHandler: props => userFilter => {
      props.storeUserFilter(userFilter);
    },
  })
);

function getCurrencyValue(currencySetting, value) {
  if (currencySetting !== undefined) {
    return new Intl.NumberFormat(
      currencySetting.locale !== undefined ? currencySetting.locale : 'en-US',
      {
        style: 'currency',
        currency:
          currencySetting.currencyCode !== undefined
            ? currencySetting.currencyCode
            : 'USD',
        minimumFractionDigits:
          currencySetting.minimumFractionDigits !== undefined
            ? currencySetting.minimumFractionDigits
            : 2,
        maximumFractionDigits:
          currencySetting.maximumFractionDigits !== undefined
            ? currencySetting.maximumFractionDigits
            : 2,
      }
    ).format(value !== undefined ? value : 0);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value !== undefined ? value : 0);
}

function transformCols(cols, actions, areTooltipsOpen, toggleTooltips) {
  const processedCols = cols.map(c => {
    const tooltipId = c.title ? `col-${c.title.replace(/ /g, '_')}` : '';

    const col = {
      ...c,
      Header: (
        <>
          <span className="column-name">
            {c.tooltipText ? (
              <>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="data-table-tooltip-icon"
                  size="1x"
                  id={tooltipId}
                  color="#232329"
                />
                <Tooltip
                  toggle={() => toggleTooltips(c.title)}
                  isOpen={areTooltipsOpen[c.title]}
                  target={tooltipId}
                  id={tooltipId}
                >
                  {c.tooltipText}
                </Tooltip>
              </>
            ) : null}
            {c.title} <FontAwesomeIcon icon={faCaretUp} className="sort-icon" />
            <FontAwesomeIcon icon={faCaretDown} className="sort-icon" />
          </span>
        </>
      ),
      accessor: c.field,
    };

    if (col.headerOverride) {
      col.Header = ({ ...props }) => col.headerOverride(props);
    }

    if (col.render) {
      col.Cell = ({ original, ...rest }) => col.render(original, rest);
    }

    if (c.type && !col.Cell) {
      if (c.type === 'date' || c.type === 'time' || c.type === 'datetime') {
        col.Cell = ({ value }) => {
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        };
      }

      if (c.type === 'currency') {
        col.Cell = ({ value }) => getCurrencyValue(c.currencySetting, value);
      }

      if (c.type === 'boolean') {
        col.Cell = ({ value }) => (value || false).toString();
      }
    }

    if (!col.Cell) {
      // To support empty values
      col.Cell = ({ value }) => value || '';
    }

    if (col.customSort) {
      col.sortMethod = col.customSort;
    }

    return col;
  });

  const processActionsCols = rowActions => {
    if (!rowActions.length) return [];

    return [
      {
        sortable: false,
        width: 294,
        className: 'table-actions',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => (
          <div className="d-flex">
            <TableActions actions={rowActions} data={original} />
          </div>
        ),
        Header: <span className="column-name">Actions</span>,
        headerClassName: 'table-actions',
      },
    ];
  };

  const additionalCols = processActionsCols(
    actions.filter(
      filterActions({
        selection: false,
      })
    )
  );

  return [...processedCols, ...additionalCols];
}

function transformDefaultSorted(defaultSorted = [], cols) {
  return [
    ...defaultSorted,
    ...cols
      .filter(x => x.defaultSort)
      .map(x => ({
        id: x.id || x.accessor,
        desc: x.defaultSort !== 'asc',
      })),
  ];
}

// function processUpdateStatusFilters(statusFilters = []) {
//   return data => {
//     if (statusFilters.length > 0) {
//       const results = data.filter(x => {
//         if (x.state) {
//           return statusFilters.includes(x.state);
//         }
//         if (x == null) {
//           return statusFilters.includes('Received');
//         }
//         return true;
//       });
//       return results;
//     }
//     return data;
//   };
// }

function processUserFilter(userFilter, currentUser, shouldUseUserFilter) {
  return data => {
    if (userFilter && shouldUseUserFilter) {
      const results = data.filter(x => x.assignedUserId === currentUser.id);
      return results;
    }
    return data;
  };
}

function processSearch(searchText = '', cols, onFetchData) {
  if (onFetchData) return data => data;
  return data => {
    if (!searchText.length) return data;

    const dataCols = cols.map(x => x.accessor);

    const results = data.filter(x => {
      const r = dataCols.map(c =>
        JSON.stringify(x[c] || '')
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      return r.filter(c => c).length > 0;
    });
    return results;
  };
}

function dataProcessing(processingOptions) {
  const {
    searchText,
    cols,
    userFilter,
    currentUser,
    shouldUseUserFilter,
    onFetchData,
    // statusFilters,
  } = processingOptions;
  return data => {
    const afterUserFilterProcessingResult = processUserFilter(
      userFilter,
      currentUser,
      shouldUseUserFilter
    )(data);
    // const afterUpdateStatusProcessingResult = processUpdateStatusFilters(
    //   statusFilters
    // )(afterUserFilterProcessingResult);
    const afterSearchProcessingResult = processSearch(
      searchText,
      cols,
      onFetchData
    )(afterUserFilterProcessingResult);
    return afterSearchProcessingResult;
  };
}

// TODO: materal-table currently makes changes to the data that makes it back to
// redux.  This stops that.  Temporary fix until I get time to fix correct
const prepareData = data =>
  data.map(x => ({
    ...x,
  }));

const getNumberOfPages = (totalItems, pageSize) =>
  Math.ceil(totalItems / pageSize) || 1;

const DataTable = ({
  actions,
  columns,
  data,
  defaultSorted,
  metaData,
  onSearchChanged,
  updateStatusFilters,
  options,
  searchText,
  statusFilters,
  showStatusFilters,
  selectedRows,
  userFilter,
  filterConfig,
  showUserFilter,
  updateUserFilterHandler,
  currentUser,
  showActionToolbar,
  getFilteredList,
  filterCounts,
  shouldUseUserFilter,
  tenantName,
  updateFiltersHandler,
  totalCount,
  onFetchData,
  activeFilter,
  search,
  customFilters,
  ...props
}) => {
  const finalOptions = {
    emptyRowsWhenPaging: false,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
    ...options,
    ...metaData,
  };

  const resultCount = totalCount || data.length;

  const [areTooltipsOpen, setAreTooltipsOpen] = useState({});

  function toggleTooltips(columnTitle) {
    setAreTooltipsOpen(prevState => ({
      ...prevState,
      [columnTitle]: !prevState[columnTitle],
    }));
  }

  const cols = transformCols(columns, actions, areTooltipsOpen, toggleTooltips);
  return (
    <ReactTable
      {...props}
      {...finalOptions}
      columns={cols}
      data={prepareData(data)}
      defaultSorted={transformDefaultSorted(defaultSorted, cols)}
      minRows={0}
      PaginationComponent={DataTablePagination}
      FilterComponent={<></>}
      resizable={false}
      pages={getNumberOfPages(resultCount, finalOptions.pageSize)}
      manual={!!onFetchData}
      resolveData={dataProcessing({
        searchText: metaData.searchText,
        statusFilters,
        cols,
        userFilter: metaData.userFilter,
        currentUser,
        getFilteredList,
        shouldUseUserFilter,
        onFetchData,
      })}
    >
      {({ filters, ...state }, makeTable) => (
        <React.Fragment>
          <TableToolbar
            onSearchChanged={onSearchChanged}
            searchText={metaData.searchText}
            updateStatusFilters={updateStatusFilters}
            statusFilters={statusFilters}
            showStatusFilters={showStatusFilters}
            actions={actions}
            state={state}
            filters={filters}
            options={options}
            selectedRows={selectedRows}
            userFilter={metaData.userFilter}
            showUserFilter={showUserFilter}
            updateUserFilter={updateUserFilterHandler}
            showActionToolbar={showActionToolbar}
            getFilteredList={getFilteredList}
            filterCounts={filterCounts}
            tenantName={tenantName}
            currentUser={currentUser}
            updateFilters={updateFiltersHandler}
            totalCount={resultCount}
            filterConfig={filterConfig}
            activeFilter={activeFilter}
            search={search}
            customFilters={customFilters}
          />
          {makeTable()}
        </React.Fragment>
      )}
    </ReactTable>
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape(),
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.func])
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  onFetchData: PropTypes.func,
  updateFiltersHandler: PropTypes.func.isRequired,
  statusFilters: PropTypes.string,
  showStatusFilters: PropTypes.bool,
  onSearchChanged: PropTypes.func.isRequired,
  updateStatusFilters: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userFilter: PropTypes.bool.isRequired,
  showUserFilter: PropTypes.bool.isRequired,
  updateUserFilter: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  showActionToolbar: PropTypes.bool.isRequired,
  getFilteredList: PropTypes.func.isRequired,
  filterCounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shouldUseUserFilter: PropTypes.bool.isRequired,
  tenantName: PropTypes.string.isRequired,
  search: PropTypes.bool,
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  metaData: PropTypes.shape({}).isRequired,
};

DataTable.defaultProps = {
  title: '',
  options: {},
  actions: [],
  data: [],
  statusFilters: '',
  showStatusFilters: false,
  searchText: '',
  defaultSorted: [],
  onFetchData: undefined,
  search: true,
};

export default enhance(DataTable);
