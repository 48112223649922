import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { withApi } from 'infrastructure/api';
import { compose, lifecycle } from 'recompose';
import injectReducer from 'infrastructure/redux/injectReducer';

import reducer from './reducer';
import makeSelectCurrentUser from './selectors';

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch, props) {
  return {
    userApi: bindActionCreators(props.api.app.user, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'currentUser', reducer });

const enhance = compose(
  withApi,
  withReducer,
  withConnect,
  lifecycle({
    componentDidMount() {
      this.props.userApi.getUserInfo();
    },
  })
);

export default enhance;
