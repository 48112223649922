import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from '@stormideas/react-sdk';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./containers/ListPage'),
  loading: () => <Loading visible />,
});
