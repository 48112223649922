import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { CLEAR_UPLOADS } from 'infrastructure/uploads/actions';
import usePrevious from 'infrastructure/hooks/usePrevious';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faChevronUp,
  faExclamation,
  faExclamationCircle,
  faFile,
  faFileVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import variables from 'scss/1-settings/colors.scss';
import './UploadSummary.scss';
import {
  PieChart,
  Collapse,
  Img,
  ProgressBar,
  Button,
} from '@stormideas/react-sdk';

const getOverallProgress = files => {
  const uploadingFile = files.find(file => file.status === 'uploading');
  const currentUploadingBatchId = uploadingFile ? uploadingFile.batchId : '';

  const currentBatchFiles = files.filter(
    file => file.batchId === currentUploadingBatchId
  );

  const totalFileSize =
    currentBatchFiles &&
    currentBatchFiles.reduce((accumulator, file) => accumulator + file.size, 0);

  const totalUploaded =
    currentBatchFiles &&
    currentBatchFiles.reduce(
      (accumulator, file) => accumulator + file.bytesUploaded,
      0
    );

  return Number((totalUploaded / totalFileSize) * 100);
};

const mapDispatchToProps = dispatch => ({
  clearUploads: upload => {
    dispatch(CLEAR_UPLOADS(upload));
  },
});

const enhance = compose(connect(null, mapDispatchToProps));

const UploadSummary = props => {
  const { cancelFunc, showCancelButton, files, clearUploads, visible } = props;
  const prevFiles = usePrevious(files);

  const [clearUploadsTimerId, setClearUploadsTimerId] = useState(null);
  const scrollableContentRef = useRef();

  const failedFiles =
    files && files.filter(file => file.status === 'failed').length;

  const hasFailedFiles = failedFiles > 0;

  const allFilesUploaded =
    files &&
    files.length > 0 &&
    files.every(file => file.status === 'uploaded');

  const overallProgress = allFilesUploaded ? 100 : getOverallProgress(files);

  const uploadComplete =
    parseInt(overallProgress, 10) === 100 || allFilesUploaded;

  const uploadingFiles =
    files.length -
    files.filter(
      file => file.status && file.status.toLowerCase() === 'uploaded'
    ).length;

  const title =
    uploadingFiles === 0 ? (
      'Upload successful!'
    ) : (
      <>
        {hasFailedFiles
          ? `Failed to upload ${failedFiles} ${
              failedFiles > 1 ? 'files' : 'file'
            }!`
          : `Uploading ${uploadingFiles} ${
              uploadingFiles > 1 ? 'files' : 'file'
            }…`}
      </>
    );
  // 1 file didn't upload.

  const collapseIcon = () => {
    if (hasFailedFiles) {
      return faExclamation;
    }
    if (uploadComplete) {
      return faCheck;
    }
    return faChevronUp;
  };

  // This should be moved to a shared location if it gets used a lot
  const fileSizeFormatter = size => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }`;
  };

  useEffect(() => {
    if (files && prevFiles && files.length > prevFiles.length) {
      if (scrollableContentRef && scrollableContentRef.current) {
        scrollableContentRef.current.scrollTop = 0;
      }
    }
  }, [files]);

  return (
    <CSSTransition
      in={visible}
      classNames="upload-summary"
      timeout={350}
      unmountOnExit
    >
      <Collapse
        onTrigger={isOpen => {
          if (allFilesUploaded && !isOpen) {
            const timeoutId = setTimeout(() => {
              clearUploads();
            }, 10000);
            setClearUploadsTimerId(timeoutId);
          }
          if (isOpen && clearUploadsTimerId !== null) {
            clearTimeout(clearUploadsTimerId);
          }
        }}
        className={classnames('upload-summary', {
          'has-cancel': !!cancelFunc && showCancelButton,
        })}
        customTrigger={
          <div className="upload-summary-header">
            {!!cancelFunc && showCancelButton && (
              <Button
                aria-label="Cancel Upload"
                buttonStyle="link"
                className="cancel-btn unstyled-button"
                color="light"
                onClick={e => {
                  e.stopPropagation();
                  if (cancelFunc) {
                    cancelFunc();
                  }
                  clearUploads();
                }}
                title="Cancel Upload"
                icon={<FontAwesomeIcon icon={faTimes} fixedWidth />}
              />
            )}
            <h4 className="title">{title}</h4>
            <div className="collapse-icon-progress">
              <PieChart
                animationDuration={50}
                aspect={1}
                data={
                  hasFailedFiles
                    ? [{ value: 100, fill: variables.danger }]
                    : [
                        {
                          value: overallProgress,
                          fill: uploadComplete
                            ? variables.success
                            : variables.primary,
                        },
                        {
                          value: 100 - overallProgress,
                          fill: 'rgba(255, 255, 255, 0.15)',
                        },
                      ]
                }
                endAngle={90}
                innerRadius="83.34%"
                label={false}
                outerRadius="100%"
                showLegend={false}
                startAngle={450}
                tooltip={false}
              />
              <div
                className={classnames('trigger-icon', {
                  'no-rotate':
                    hasFailedFiles || (uploadComplete && !hasFailedFiles),
                })}
              >
                <FontAwesomeIcon fixedWidth icon={collapseIcon()} />
              </div>
            </div>
          </div>
        }
        defaultOpen
      >
        <div
          className="file-list-container light-scrollbar"
          ref={scrollableContentRef}
        >
          {files &&
            files.map((file, i) => (
              <div className="upload-preview-row" index={i}>
                <div className="file-preview">
                  {file.type.toLowerCase() === 'image' ? (
                    <div
                      style={{
                        transform: `rotate(${file.rotation}deg)`,
                      }}
                    >
                      <Img
                        alt={file.name}
                        blurFill
                        containerClass="thumbnail"
                        src={file.src}
                      />
                    </div>
                  ) : (
                    <FontAwesomeIcon
                      className="placeholder-icon"
                      icon={
                        file.type.toLowerCase() === 'video'
                          ? faFileVideo
                          : faFile
                      }
                    />
                  )}
                </div>
                <div className="file-info-container">
                  <div className="file-info">
                    <div className="file-name">{file.name}</div>
                    <small className="file-size text-muted">
                      {file.status === 'failed'
                        ? 'Failed'
                        : fileSizeFormatter(file.size)}
                    </small>
                    {!!file.bytesUploaded &&
                      file.status !== 'uploaded' &&
                      file.status !== 'failed' && (
                        <ProgressBar
                          color="info"
                          value={file.bytesUploaded}
                          total={file.size}
                          size="xs"
                          className="uploading-bar"
                        />
                      )}
                  </div>
                </div>
                <div className="status-icon">
                  {file.status === 'uploaded' && (
                    <FontAwesomeIcon
                      className="text-success"
                      fixedWidth
                      icon={faCheckCircle}
                    />
                  )}
                  {file.status === 'failed' && (
                    <FontAwesomeIcon
                      className="text-danger"
                      fixedWidth
                      icon={faExclamationCircle}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </Collapse>
    </CSSTransition>
  );
};

UploadSummary.propTypes = {
  /** Called when the cancel button is selected */
  cancelFunc: PropTypes.func,
  /** Show the cancel button */
  showCancelButton: PropTypes.bool,
  /** Files being uploaded/that have been uploaded */
  files: PropTypes.arrayOf(
    PropTypes.shape({
      batchId: PropTypes.string,
      bytesUploaded: PropTypes.number,
      id: PropTypes.string,
      src: PropTypes.string,
      name: PropTypes.string,
      rotation: PropTypes.number,
      size: PropTypes.number,
      status: PropTypes.oneOf([null, 'failed', 'uploaded', 'uploading']),
      type: PropTypes.string,
    })
  ),
  clearUploads: PropTypes.func,
  visible: PropTypes.bool,
};

UploadSummary.defaultProps = {
  cancelFunc: () => {},
  showCancelButton: false,
  files: null,
  clearUploads: () => {},
  visible: false,
};

export default enhance(UploadSummary);
