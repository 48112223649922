import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import AssetDetail from 'components/Assets/AssetDetail';
import Panel from 'components/Panel';
import './AssetCard.scss';
import { Img, Button } from '@stormideas/react-sdk';

/** A small card to be used as a file or folder preview. */

class AssetCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;
    const {
      assetDetailProps,
      childAssets,
      childFolders,
      cols,
      dateAdded,
      icon,
      modal,
      path,
      thumbnail,
      title,
    } = this.props;
    return (
      <React.Fragment>
        <Col {...cols} widths={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']}>
          <Panel
            tag="button"
            imgTop={
              <div className="thumbnail">
                {thumbnail ? (
                  <div className="image-container">
                    <Img src={thumbnail} alt={title} blurFill />
                  </div>
                ) : (
                  icon
                )}
              </div>
            }
            className="asset-card"
            onClick={modal ? this.toggle : null}
          >
            <div className="metadata">
              <h3 className="title">{title}</h3>
              {dateAdded && <small className="date">Added: {dateAdded}</small>}
              <small className="contents">
                {childFolders && `${childFolders} Folders, `}
                {childAssets && `${childAssets} Assets`}
              </small>
            </div>
          </Panel>
        </Col>
        {modal && (
          <Modal
            className="asset-detail-modal"
            isOpen={isOpen}
            size="lg"
            toggle={this.toggle}
            path={path}
          >
            <Button
              className="close-btn rounded-pill"
              color="light"
              onClick={this.toggle}
              type="button"
              icon={<span className="lnr lnr-cross" />}
            />
            <ModalBody>
              <AssetDetail
                {...assetDetailProps}
                title={title}
                path={path}
                asset={<Img src={thumbnail} alt={title} blurFill />}
              />
            </ModalBody>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

AssetCard.propTypes = {
  /** Asset type, to filter */
  assetType: PropTypes.oneOf(['folder', 'image', 'video']),
  /** Number of children in a folder */
  childAssets: PropTypes.number,
  /** Number of child folders in a folder */
  childFolders: PropTypes.number,
  /** Object to define the responsive layout, see https://reactstrap.github.io/components/layout/ */
  cols: PropTypes.shape({
    size: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** Date created */
  dateAdded: PropTypes.string,
  /** Icon node */
  icon: PropTypes.node,
  /** Asset details component */
  modal: PropTypes.bool,
  /** File path */
  path: PropTypes.string,
  /** Props for AssetDetail component */
  assetDetailProps: PropTypes.shape({}),
  /** Thumbnail image in place of an icon */
  thumbnail: PropTypes.string,
  /** Folder or file name */
  title: PropTypes.string.isRequired,
};

AssetCard.defaultProps = {
  assetType: 'folder',
  childAssets: null,
  childFolders: null,
  cols: {
    xs: 6,
    lg: 4,
    xxl: 3,
  },
  dateAdded: null,
  icon: <FontAwesomeIcon icon={faFolder} className="asset-icon folder-icon" />,
  modal: false,
  path: '',
  assetDetailProps: {},
  thumbnail: null,
};

export default AssetCard;
