import React from 'react';
import { render } from 'react-dom';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import history from 'infrastructure/history';

// This needs to be the first stylesheet imported so component stylesheets
// will overwrite it without having to add extra specificity.
import 'scss/app.scss';

import startApp from 'infrastructure/start';
import { configureStore } from 'infrastructure/redux';
import { errorHandler, ErrorPage } from 'infrastructure/error';
import { getCookie, setCookie } from 'infrastructure/security/cookies';

import App from 'containers/App';

import * as serviceWorker from './serviceWorker';

const initialstate = getCookie('app') ? JSON.parse(getCookie('app')) : {};
const configuredStore = configureStore(initialstate);

if (process.env.NODE_ENV === 'development') {
  configuredStore.subscribe(() => {
    const state = configuredStore.getState();
    if (state.account) {
      const { account, dataTable } = state;
      setCookie('app', JSON.stringify({ account, dataTable }), 30);
    }
  });
}

if (process.env.NODE_ENV === 'production') {
  const onerror = errorHandler(configuredStore);
  global.onerror = onerror;

  configuredStore.subscribe(() => {
    const state = configuredStore.getState();
    if (state.account) {
      const { account, dataTable } = state;
      setCookie('app', JSON.stringify({ account, dataTable }), 30);
    }
  });
}

startApp(
  store => render(<App store={store} />, document.getElementById('root')),
  configuredStore,
  () => render(<ErrorPage canRetry />, document.getElementById('root'))
);

const state = configuredStore.getState();
const { config } = state;

let appInsightsKey;

setTimeout(() => {
  if (window.config && window.config.applicationInsightsInstrumentationKey) {
    appInsightsKey = window.config.applicationInsightsInstrumentationKey;
  } else if (config && config.applicationInsightsInstrumentationKey) {
    appInsightsKey = config.applicationInsightsInstrumentationKey;
  }

  const telemetryInitializer = envelope => {
    const reduxState = configuredStore.getState();
    const { currentUser } = reduxState;

    if (envelope.data && currentUser) {
      // eslint-disable-next-line no-param-reassign
      envelope.data = {
        ...envelope.data,
        UserId: currentUser.id,
        UserName: currentUser.name,
        UserRole: currentUser.role,
      };
    }
  };

  if (appInsightsKey) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);
  }
}, 2000);
serviceWorker.unregister({
  onUpdate: () =>
    configuredStore.dispatch({
      type: 'SW_UPDATE_AVAILABLE',
    }),
  onSuccess: () =>
    configuredStore.dispatch({
      type: 'SW_INSTALLED',
    }),
});
