import React from 'react';
import PropTypes from 'prop-types';
import { Content as ContentSDK } from '@stormideas/react-sdk';

import './Content.scss';

const Content = ({ children, className, fluid, topRightContent, ...props }) => (
  <ContentSDK
    className={`${className} ${fluid ? 'fluid' : ''}`}
    pageHeaderContent={topRightContent}
    {...props}
  >
    {children}
  </ContentSDK>
);

Content.propTypes = {
  /** App contents */
  children: PropTypes.node,
  /** Container classes */
  className: PropTypes.string,
  /** An array of parent pages for Breadcrumbs */
  path: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  /** Page subtitle */
  subHeading: PropTypes.string,
  /** Title of the page */
  title: PropTypes.string,
  /** Used for buttons etc in the top right of the page */
  topRightContent: PropTypes.node,
  /** Whether the container should be fluid or not */
  fluid: PropTypes.bool,
};

Content.defaultProps = {
  children: null,
  className: null,
  path: [{ url: '/', displayName: 'Home' }],
  subHeading: null,
  title: null,
  topRightContent: null,
  fluid: false,
};

export default Content;
