import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faGripVertical,
} from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@stormideas/react-sdk';
import { visibility } from 'infrastructure/enhancers';

const MultipleFieldItems = ({
  Component,
  lineItem,
  name,
  values,
  remove,
  ...props
}) => (
  <React.Fragment>
    {values.map((_, index) => (
      <fieldset className="fieldset" key={`${name + index}`}>
        <legend>
          {lineItem} {index + 1}
          <Button
            aria-label="Remove"
            className="btn-light icon fieldset-right-icon rounded-circle"
            buttonStyle="link"
            onClick={() => {
              remove(index);
              document.activeElement.blur();
            }}
            outline
            color="danger"
            title="Remove"
            icon={
              <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
            }
          />
        </legend>
        <Draggable
          key={`${name + index}`}
          draggableId={`${name + index}`}
          index={index}
        >
          {(p, s) => (
            <div
              className={classnames('list-group-item', {
                active: s.isDragging,
              })}
              ref={p.innerRef}
              {...p.draggableProps}
              {...p.dragHandleProps}
            >
              <FontAwesomeIcon
                icon={faGripVertical}
                className="icon drag-handle"
              />
              <Component
                {...props}
                key={`${name + index}`}
                index={index}
                name={`${name}[${index}]`}
              />
            </div>
          )}
        </Draggable>
      </fieldset>
    ))}
  </React.Fragment>
);
MultipleFieldItems.propTypes = {
  Component: PropTypes.func.isRequired,
  lineItem: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
};

export default visibility(MultipleFieldItems);
