import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UIMessage } from '@stormideas/react-sdk';
import { Content } from 'components';
import { withCurrentUser } from 'infrastructure/currentUser';

const Dashboard = ({ currentUser }) => {
  const [currentUserFirstName, setCurrentUserFirstName] = useState('');
  useEffect(() => {
    if (currentUser.name) {
      setCurrentUserFirstName(currentUser.name.split(' ')[0]);
    }
  }, [currentUser]);
  return (
    <Content title="Dashboard" className="dashboard">
      <UIMessage
        // eslint-disable-next-line max-len
        title={
          currentUserFirstName
            ? `Welcome to StormKit, ${currentUserFirstName}.`
            : 'Welcome to StormKit'
        }
        message="You can navigate using the different sections in the sidebar on the left."
        // cta={
        //   <Button tag={Link} to="/caption-requests" color="primary">
        //     Get started with caption requests
        //   </Button>
        // }
      />
    </Content>
  );
};

Dashboard.propTypes = {
  currentUser: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

export default withCurrentUser(Dashboard);
