import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import './Panel.scss';

/** A card or panel fon containing content on the page,
 * primarily for providing some visual structure. */

const Panel = withRouter(props => {
  const {
    backgroundColor,
    titleRight,
    children,
    className,
    headerBackground,
    headerGradient,
    icon,
    imgBottom,
    imgTop,
    location,
    onClick,
    overlayContent,
    subtitle,
    tag,
    title,
    topBorder,
  } = props;

  const cardClassName = `card-${location.pathname.replace(/\//g, '')}`;

  const content = (
    <>
      {(title || subtitle) && (
        <CardHeader
          className={classnames({
            [`bg-${headerBackground}`]: headerBackground,
            gradient: headerGradient,
          })}
        >
          <div>
            {(title || icon) && (
              <CardTitle tag="h2" className="h5">
                {icon && <span className="card-icon">{icon}</span>}
                {title}
              </CardTitle>
            )}
            {subtitle && <CardSubtitle tag="h6">{subtitle}</CardSubtitle>}
          </div>
          {titleRight && <div className="title-right">{titleRight}</div>}
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
    </>
  );

  return (
    <Card
      className={classnames(
        {
          [`bg-${backgroundColor}`]: backgroundColor,
          [`border-${topBorder}`]: topBorder,
          'top-border': topBorder,
          clip: overlayContent,
        },
        'panel',
        className,
        cardClassName
      )}
      onClick={onClick}
      tag={tag}
    >
      {imgTop}

      {overlayContent ? (
        <div
          className={classnames({
            'card-img-overlay': overlayContent && (imgBottom || imgTop),
          })}
        >
          {content}
        </div>
      ) : (
        content
      )}
      {imgBottom}
    </Card>
  );
});

// const Panel = props => {
//   const {
//     backgroundColor,
//     titleRight,
//     children,
//     className,
//     headerBackground,
//     headerGradient,
//     icon,
//     imgBottom,
//     imgTop,
//     onClick,
//     overlayContent,
//     subtitle,
//     tag,
//     title,
//     topBorder,
//   } = props;

//   const location = useLocation();

//   const content = (
//     <>
//       {(title || subtitle) && (
//         <CardHeader
//           className={classnames({
//             [`bg-${headerBackground}`]: headerBackground,
//             gradient: headerGradient,
//           })}
//         >
//           <div>
//             {(title || icon) && (
//               <CardTitle tag="h2" className="h5">
//                 {icon && <span className="card-icon">{icon}</span>}
//                 {title}
//                 {location.pathname}
//               </CardTitle>
//             )}
//             {subtitle && <CardSubtitle tag="h6">{subtitle}</CardSubtitle>}
//           </div>
//           {titleRight && <div className="title-right">{titleRight}</div>}
//         </CardHeader>
//       )}
//       <CardBody>{children}</CardBody>
//     </>
//   );

//   return (
//     <Card
//       className={classnames(
//         {
//           [`bg-${backgroundColor}`]: backgroundColor,
//           [`border-${topBorder}`]: topBorder,
//           'top-border': topBorder,
//           clip: overlayContent,
//         },
//         'panel',
//         className
//       )}
//       onClick={onClick}
//       tag={tag}
//     >
//       {imgTop}

//       {overlayContent ? (
//         <div
//           className={classnames({
//             'card-img-overlay': overlayContent && (imgBottom || imgTop),
//           })}
//         >
//           {content}
//         </div>
//       ) : (
//         content
//       )}
//       {imgBottom}
//     </Card>
//   );
// };

const backgrounds = [
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'danger',
  'orange',
  'warning',
  'yellow',
  'green',
  'primary',
  'success',
  'teal',
  'cyan',
  'info',
  'light',
  'secondary',
  'dark',
];

Panel.propTypes = {
  /** Bootstrap colors for the main background, usually the same as headerBackground */
  backgroundColor: PropTypes.oneOf(backgrounds),
  /** Main content */
  children: PropTypes.node,
  /** Custom classes */
  className: PropTypes.string,
  /** Background color just for the header, usually the same as backgroundColor */
  headerBackground: PropTypes.oneOf(backgrounds),
  /** Use a gradient for the header background */
  headerGradient: PropTypes.bool,
  /** Icon to display before the title */
  icon: PropTypes.node,
  /** An image instead of the footer */
  imgBottom: PropTypes.node,
  /** An image instead of the header */
  imgTop: PropTypes.node,
  /** onClick handler for the whole card */
  onClick: PropTypes.func,
  /** Use an image as the background, requires a top or bottom image */
  overlayContent: PropTypes.bool,
  /** Subtitle in the header */
  subtitle: PropTypes.string,
  /** Tag for the card, defaults to div but could be a button if using a click handler */
  tag: PropTypes.node,
  /** Card title */
  title: PropTypes.string,
  /** Buttons in the top right */
  titleRight: PropTypes.node,
  /** A highlight color for the top border */
  topBorder: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
};

Panel.defaultProps = {
  backgroundColor: null,
  children: null,
  className: '',
  headerBackground: null,
  headerGradient: false,
  icon: null,
  imgBottom: null,
  imgTop: null,
  onClick: () => false,
  overlayContent: false,
  subtitle: null,
  tag: 'div',
  title: null,
  titleRight: null,
  topBorder: null,
};

export default Panel;
