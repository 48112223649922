import React from 'react';
import PropTypes from 'prop-types';
import FolderListItem from 'components/Assets/FolderListItem';
import { nothingIf } from 'infrastructure/enhancers';

import './FolderList.scss';

const Folder = ({ folder }) => (
  <FolderListItem {...folder}>
    <Contents folders={folder.items} />
  </FolderListItem>
);

Folder.propTypes = {
  folder: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
  }),
};

Folder.defaultProps = {
  folder: null,
};

const Contents = nothingIf(x => !x.folders)(props => {
  const { folders } = props;

  return (
    <React.Fragment>
      {folders.map((folder, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Folder folder={folder} key={i} />
      ))}
    </React.Fragment>
  );
});

/** WIP folder tree component. Needs URLs or Paths added to the data for links. */

const FolderList = nothingIf(x => !x.folders)(props => {
  const { folders } = props;

  return (
    <div className="folder-list-container">
      <ul className="folder-list">
        <Contents folders={folders} />
      </ul>
    </div>
  );
});

FolderList.propTypes = {
  /** `title` or `items` is required, items are a recursive object in the same shape as below. */
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
};

FolderList.defaultProps = {
  folders: null,
};

export default FolderList;
