import React from 'react';
import PropTypes from 'prop-types';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

/**
 * A description of the component and when it should be used.
 */

class TextEditorBase extends React.Component {
  constructor(props) {
    super(props);
    let editorState;
    if (props.initialValue && props.initialValue !== '<p></p>\n') {
      editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(props.initialValue))
      );
    } else {
      editorState = EditorState.createEmpty();
    }
    this.state = {
      editorState,
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange = editorState => {
    const { onChange } = this.props;
    this.setState({
      editorState,
    });
    if (onChange) {
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };

  render() {
    const { editorState } = this.state;
    const { toolbarOptions } = this.props;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="text-editor-container"
        toolbarClassName="text-editor-toolbar"
        editorClassName="text-editor-area"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={toolbarOptions}
      />
    );
  }
}

TextEditorBase.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.node,
  toolbarOptions: PropTypes.shape({}),
};

TextEditorBase.defaultProps = {
  initialValue: null,
  toolbarOptions: null,
};

export default TextEditorBase;
