import { createReducer } from 'redux-act';
import {
  changeSort,
  changePage,
  changeRowsPerPage,
  changeFilters,
  changeSearch,
  changeUserFilter,
} from './actions';

export const STORE = 'makes';

export const states = {
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
};

const initialState = {};

const update = (state, { name, ...payload }) => ({
  ...state,
  [name]: {
    ...(state[name] || {}),
    ...payload,
  },
});

const reducer = createReducer(
  {
    [changeSort]: update,
    [changePage]: update,
    [changeRowsPerPage]: update,
    [changeFilters]: update,
    [changeSearch]: update,
    [changeUserFilter]: update,
  },
  initialState
);

export default reducer;
