import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Label, Row } from 'reactstrap';
import { Button } from '@stormideas/react-sdk';
import DropZone from 'components/Form/DropZone';
import FolderList from 'components/Assets/FolderList';
import { withForm } from 'infrastructure/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import AssetToolbar from '../AssetToolbar';

const EmptyMessage = withForm({})(() => (
  <Col>
    {/* Would be nice to have the blank slate in the
    dropzone but the component doesn't support it yet. */}
    <h2 className="h4 mb-4">There are no assets here yet.</h2>
    <Label for="asset" className="sr-only">
      Upload Assets
    </Label>
    <DropZone name="asset" />
  </Col>
));

const search = (
  <>
    <Label className="sr-only" htmlFor="assetSearch">
      Search
    </Label>
    <Input
      className="search-input input-icon-right"
      id="assetSearch"
      name="assetSearch"
      placeholder="eg. Folder or File Name"
      type="search"
    />
    <Button
      aria-label="Search Assets"
      type="button"
      className="input-group-append"
      icon={<FontAwesomeIcon icon={faSearch} />}
    />
  </>
);

const defaultActions = (
  <Button
    type="button"
    icon={<FontAwesomeIcon icon={faPlus} />}
    color="primary"
    className="create-btn"
  >
    <span className="d-none d-lg-inline">New</span>
  </Button>
);

/** WIP. Asset/file browser */

const AssetBrowser = ({
  actions,
  children,
  folderList,
  showToolbar,
  toolbarProps,
}) => {
  const filteredContent = React.Children.toArray(children)
    .filter(child => child.props.assetType === toolbarProps.activeToolbarFilter)
    .map(image => image);

  return (
    <>
      {showToolbar && (
        <AssetToolbar
          search={search}
          actions={actions}
          showSort
          {...toolbarProps}
        />
      )}
      <Row>
        {folderList && (
          <Col md={4} lg={3}>
            <FolderList folders={folderList} />
          </Col>
        )}
        <Col md={folderList ? 8 : 12} lg={folderList ? 9 : 12}>
          <Row>
            {toolbarProps.activeToolbarFilter === 'all'
              ? children
              : filteredContent}
          </Row>
        </Col>
      </Row>
    </>
  );
};

AssetBrowser.propTypes = {
  /** Folders and files */
  children: PropTypes.node,
  /** List of folders in the sidebar, title or items is required */
  folderList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      title: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  /** Show search etc. */
  showToolbar: PropTypes.bool,
  /** Action buttons, 'new +' button  */
  actions: PropTypes.node,
  toolbarProps: PropTypes.shape({
    activeToolbarFilter: PropTypes.string,
  }).isRequired,
};

AssetBrowser.defaultProps = {
  children: <EmptyMessage />,
  folderList: null,
  showToolbar: true,
  actions: defaultActions,
};

export default AssetBrowser;
