// eslint-disable-next-line import/no-extraneous-dependencies
import { hot, setConfig } from 'react-hot-loader';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { compose } from 'recompose';
import history from 'infrastructure/history';

import injectSaga from 'infrastructure/redux/injectSaga';
import { DAEMON } from 'infrastructure/redux/constants';

import { Loading } from '@stormideas/react-sdk';

import { saga as errorSaga } from 'infrastructure/error';
import { saga as securitySaga } from 'infrastructure/security';

import AppRouter from 'Router';
import ScrollToTop from './ScrollToTop';
import saga from './saga';

setConfig({ pureSFC: true });

const withSaga = compose(
  injectSaga({
    key: 'error',
    saga: errorSaga,
    mode: DAEMON,
  }),
  injectSaga({
    key: 'security',
    saga: securitySaga,
    mode: DAEMON,
  }),
  injectSaga({ key: 'app', saga, mode: DAEMON })
);

// const enhanceUploader = compose(
//   connect(state => ({
//     uploads: state.uploads.uploads,
//     uploaderVisible:
//       state.uploads &&
//       state.uploads.uploads &&
//       state.uploads.uploads.length > 0,
//   }))
// );

const enhanceContent = compose(
  withSaga,
  connect(state => ({
    theme: state.layout.theme.theme,
    uploaderVisible:
      state.uploads &&
      state.uploads.uploads &&
      state.uploads.uploads.length > 0,
  }))
);

const Content = enhanceContent(({ uploaderVisible }) => (
  <AppRouter className={uploaderVisible ? 'uploader-visible' : ''} />
));

// const Uploads = enhanceUploader(({ uploads, uploaderVisible }) => (
//   <UploadSummary files={uploads} visible={uploaderVisible} />
// ));

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    const { store } = this.props;

    return (
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop>
            <>
              <Loading visibile={!loaded} loading={loading} />
              <Content />
              {/* <Uploads /> */}
            </>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}
App.propTypes = {
  store: PropTypes.shape({}).isRequired,
};

export default hot(module)(App);
