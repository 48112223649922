import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@stormideas/react-sdk';

import './Chip.scss';

/** Similar to Bootstrap Badges, with an attached button and small/medium/large sizes */

const Chip = props => {
  const { buttonFunc, children, color, size, className } = props;
  return (
    <div
      className={classnames(
        'chip',
        `bg-${color}`,
        {
          'chip-sm': size === 'sm',
          'chip-lg': size === 'lg',
        },
        className
      )}
    >
      {buttonFunc && (
        <Button
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={buttonFunc}
        />
      )}
      <div className="chip-name">{children}</div>
    </div>
  );
};

Chip.propTypes = {
  /** Function for button onClick */
  buttonFunc: PropTypes.func,
  /** Chip Contents */
  children: PropTypes.node,
  /** Additional styling */
  className: PropTypes.string,
  /** Background color */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  /** Size of the badge */
  size: PropTypes.oneOf(['sm', 'lg', null]),
};

Chip.defaultProps = {
  children: null,
  className: null,
  buttonFunc: null,
  color: 'info',
  size: null,
};

export default Chip;
