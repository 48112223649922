import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import Select, { Creatable } from 'react-select';
import { FormFeedback } from 'reactstrap';
import { startCase } from 'lodash';
import classnames from 'classnames';
import { processValidate, getValidity } from 'infrastructure/form';
import variables from 'scss/1-settings/colors.scss';
import './SelectField.scss';

/** Custom select field for when we need to be able to select multiple
 * items and have more flexibility than with a HTML select field */

const reactSelectTheme = theme => ({
  ...theme,
  borderRadius: '0.2rem',
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const findValue = (options, field, isMulti, defaultValue, creatable) => {
  if (!field || !field.value) {
    return defaultValue
      ? (options || []).find(x => x.value === defaultValue)
      : null;
  }

  if (!isMulti) {
    return (options || []).find(x => x.value === field.value);
  }
  const values = !field.value.includes
    ? []
    : (options || []).filter(x => field.value.includes(x.value));
  return creatable
    ? [
        ...values,
        ...field.value
          .filter(x => !options.map(y => y.value).includes(x))
          .map(z => ({ label: z, value: z })),
      ]
    : values;
};

const renderSelect = ({
  className,
  defaultValue,
  field,
  form,
  isMulti,
  onChange,
  options,
  creatable,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!creatable && defaultValue !== undefined && !field.value) {
      form.setFieldValue(field.name, defaultValue);
    }
  }, []);
  const { touched = {}, errors = {} } = form;
  const validity = getValidity(
    getIn(touched, field.name),
    getIn(errors, field.name)
  );

  return (
    <React.Fragment>
      {creatable ? (
        <Creatable
          {...props}
          classNamePrefix="rs"
          isMulti={isMulti}
          defaultValue={findValue(
            options,
            field,
            isMulti,
            defaultValue,
            creatable
          )}
          theme={reactSelectTheme}
          className={classnames(className, {
            'is-invalid': validity.invalid,
            'is-valid': validity.valid,
          })}
          onChange={option => {
            const value = isMulti ? option.map(x => x.value) : option.value;
            form.setFieldValue(field.name, value);
            form.setFieldTouched(field.name, true);
            onChange(value);
          }}
          options={options}
          value={findValue(options, field, isMulti, defaultValue, creatable)}
          openMenuOnFocus
        />
      ) : (
        <Select
          {...props}
          classNamePrefix="rs"
          defaultValue={findValue(options, field, isMulti, defaultValue)}
          isMulti={isMulti}
          theme={reactSelectTheme}
          className={classnames(className, {
            'is-invalid': validity.invalid,
            'is-valid': validity.valid,
          })}
          onChange={option => {
            const value = isMulti ? option.map(x => x.value) : option.value;
            form.setFieldValue(field.name, value);
            form.setFieldTouched(field.name, true);
            onChange(value);
          }}
          onBlur={() => form.setFieldTouched(field.name, true)}
          options={options}
          value={findValue(options, field, isMulti, defaultValue)}
          openMenuOnFocus
        />
      )}
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <FormFeedback className="validation form__form-group-error" tag="small">
          {getIn(errors, field.name)}
        </FormFeedback>
      )}
    </React.Fragment>
  );
};

renderSelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  creatable: PropTypes.bool,
};

renderSelect.defaultProps = {
  className: '',
  defaultValue: {},
  field: {},
  form: {},
  isMulti: false,
  onChange: () => {},
  creatable: false,
};

const SelectField = props => {
  const { validate, label, name } = props;
  return (
    <Field
      {...props}
      component={renderSelect}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
    />
  );
};

SelectField.propTypes = {
  /** Custom style class */
  className: PropTypes.string,
  /** Label for the input, used in validation */
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string.isRequired,
  /** Validation array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  /** onChange function handler */
  onChange: PropTypes.func,
  creatable: PropTypes.bool,
};

SelectField.defaultProps = {
  className: '',
  label: '',
  onChange: () => {},
  validate: [],
  creatable: false,
};

export default SelectField;
