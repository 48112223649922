import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { getValidity } from './validate';

const renderField = ({
  field,
  form: { touched = {}, errors = {} },
  ...props
}) => (
  <React.Fragment>
    <Input
      {...props}
      {...field}
      {...getValidity(getIn(touched, field.name), getIn(errors, field.name))}
    />
    {getIn(touched, field.name) && getIn(errors, field.name) && (
      <FormFeedback className="validation form__form-group-error" tag="small">
        {getIn(errors, field.name)}
      </FormFeedback>
    )}
  </React.Fragment>
);

renderField.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  form: { touched: {}, errors: {} },
  placeholder: '',
  meta: null,
  type: 'text',
};

export default renderField;
