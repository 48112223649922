import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import URI from 'urijs';
import { NavLink } from 'react-router-dom';

const sidebarLinkPropTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

const sidebarLinkDefaultProps = {
  route: '',
};

const LinkContent = ({ title, icon, newLink }) => (
  <React.Fragment>
    {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
    <p className="sidebar__link-title">
      {title}
      {newLink ? (
        <Badge className="sidebar__link-badge">
          <span>New</span>
        </Badge>
      ) : (
        ''
      )}
    </p>
  </React.Fragment>
);

LinkContent.propTypes = {
  ...sidebarLinkPropTypes,
};

LinkContent.defaultProps = {
  ...sidebarLinkDefaultProps,
};

const ExternalLink = ({ route, ...props }) => (
  <li className="sidebar__link">
    <a href={route} className="sidebar__link-title">
      <LinkContent {...props} />
    </a>
  </li>
);

ExternalLink.propTypes = {
  ...sidebarLinkPropTypes,
};

ExternalLink.defaultProps = {
  ...sidebarLinkDefaultProps,
};

const InternalLink = ({ route, onClick, ...props }) => (
  <NavLink
    to={route}
    onClick={onClick}
    className="sidebar__link"
    activeClassName="active"
  >
    <LinkContent {...props} />
  </NavLink>
);

InternalLink.propTypes = {
  ...sidebarLinkPropTypes,
};

InternalLink.defaultProps = {
  ...sidebarLinkDefaultProps,
};

const SidebarLink = ({ route, ...props }) =>
  URI(route).protocol() ? (
    <ExternalLink route={route} {...props} />
  ) : (
    <InternalLink route={route} {...props} />
  );

SidebarLink.propTypes = {
  ...sidebarLinkPropTypes,
};

SidebarLink.defaultProps = {
  ...sidebarLinkDefaultProps,
};

export default SidebarLink;
