import {
  // faPlus,
  // faCopy,
  faTrashAlt,
  faPencil,
  faExternalLink,
  faUpload,
  faArrowRight,
  faArrowToBottom,
} from '@fortawesome/pro-regular-svg-icons';

const buildActions = (handlers, props) => [
  {
    tooltip: handlers.tableToolbarButtonText || 'New',
    icon: 'add_circle_outline',
    color: 'primary',
    isFreeAction: true,
    onClick: () => handlers.new(props),
    toolbarIcon: handlers.tableToolbarButtonIcon || faUpload,
  },
  // () => {
  //   const {
  //     api: { copy },
  //   } = props;

  //   if (handlers.copy && (copy || handlers.supportsCopy)) {
  //     return {
  //       title: 'Duplicate',
  //       tooltip: 'Duplicate',
  //       icon: faCopy,
  //       isRowAction: true,
  //       onClick: (_, item) => handlers.copy(item, props),
  //     };
  //   }

  //   return null;
  // },
  () =>
    handlers.download && {
      title: 'Download',
      tooltip: 'Download',
      buttonStyle: 'outline',
      color: 'dark',
      icon: faArrowToBottom,
      isSelectedAction: true,
      isRowAction: true,
      iconOnly: true,
      onClick: (_, item) => handlers.download(item, props),
    },
  () =>
    handlers.open && {
      title: 'Open',
      tooltip: 'Open',
      icon: faExternalLink,
      isRowAction: true,
      buttonStyle: 'outline',
      onClick: (_, item) => handlers.open(item),
    },
  rowItem => {
    if (handlers.hideEdit && handlers.hideEdit(rowItem)) return null;
    return {
      title: 'Edit',
      tooltip: 'Edit',
      color: 'primary',
      icon: faPencil,
      isSelectedAction: false,
      isRowAction: true,
      onClick: (_, item) => handlers.edit(item, props),
    };
  },
  () =>
    handlers.changeState && {
      title: 'Send to Prep',
      tooltip: 'Send to Prep',
      color: 'info',
      iconAfter: true,
      icon: faArrowRight,
      isSelectedAction: true,
      isRowAction: true,
      iconOnly: true,
      onClick: (_, item) => handlers.changeState(item, props),
    },
  rowItem => {
    const disabled =
      typeof handlers.supportsDelete === 'function'
        ? !handlers.supportsDelete(rowItem, props)
        : !handlers.supportsDelete;
    if (handlers.hideDelete && handlers.hideDelete(rowItem)) return null;
    if (handlers.delete) {
      return {
        buttonStyle: 'outline',
        color: 'danger',
        icon: faTrashAlt,
        isRowAction: true,
        isSelectedAction: true,
        disabled,
        onClick: (_, item) => handlers.delete(item, props),
        toolbarIcon: faTrashAlt,
      };
    }

    return null;
  },
  // data => {
  //   if (
  //     (!data.url && !(handlers.showOpenUrl && handlers.showOpenUrl(data))) ||
  //     !handlers.openExternal
  //   )
  //     return null;

  //   return {
  //     tooltip: 'View Online',
  //     icon: faExternalLink,
  //     isRowAction: true,
  //     onClick: (_, item) => handlers.openExternal(item, props),
  //   };
  // },
];

export default (handlers, props) =>
  buildActions(
    {
      openExternal: item => {
        window.open(item.url);
        document.activeElement.blur();
      },
      ...handlers,
    },
    props
  );
