import React from 'react';
import { takeEvery, call } from 'redux-saga/effects';
import { toast } from 'components';

export function* handle500(action) {
  yield call(() => toast.error(action.payload.message));
}

export function* handleCaptionTransitionInvalidation(action) {
  yield call(() => {
    const {
      payload: {
        response: {
          error: { validationErrors },
        },
      },
    } = action;
    const validationError = validationErrors[0];
    const members = validationError.members.map(x =>
      React.createElement('li', { key: `listItem${x}` }, x)
    );
    const memberList = React.createElement('ul', {}, members);
    const message = React.createElement(
      'span',
      {},
      validationError.message,
      memberList
    );
    toast.error(message);
  });
}

function* erroListener() {
  yield takeEvery(
    x =>
      (x.type === 'API_FAILURE' && x.payload.status === 500) ||
      (x.type === 'API_REQUEST' && x.error),
    handle500
  );

  yield takeEvery(
    x =>
      x.type === 'API_FAILURE' &&
      x.payload.status === 400 &&
      x.meta.type === 'app/captionRequest/CHANGE_STATE' &&
      !x.meta.isArray,
    handleCaptionTransitionInvalidation
  );
}

export default erroListener;
