import { createReducer } from 'redux-act';
import {
  ADD_UPLOAD,
  REMOVE_UPLOAD,
  UPDATE_UPLOAD,
  CLEAR_UPLOADS,
} from './actions';

const initialState = {
  uploads: [],
};

export default createReducer(
  {
    [ADD_UPLOAD]: (state, payload) => {
      const { uploads } = state;
      const batchIdArray = uploads.map(x => x.batchId);
      const indexToFollow = batchIdArray.lastIndexOf(payload.batchId);

      if (indexToFollow !== -1) {
        const newUploads = uploads.slice();
        newUploads.splice(indexToFollow + 1, 0, payload);
        return {
          ...state,
          uploads: newUploads,
        };
      }
      return {
        ...state,
        uploads: [payload, ...state.uploads],
      };
    },
    [REMOVE_UPLOAD]: (state, payload) => {
      const { uploads } = state;
      return {
        ...state,
        uploads: [...uploads.filter(x => x.id !== payload.id)],
      };
    },
    [UPDATE_UPLOAD]: (state, payload) => {
      const { uploads } = state;
      const indexToUpdate = uploads.findIndex(x => x.id === payload.id);

      return {
        ...state,
        uploads: uploads.map((upload, index) => {
          if (index !== indexToUpdate) {
            return upload;
          }
          return {
            ...upload,
            ...payload,
            src: upload.src || payload.src,
          };
        }),
      };
    },
    [CLEAR_UPLOADS]: state => ({
      ...state,
      uploads: [],
    }),
  },
  initialState
);
