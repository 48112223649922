import { combineReducers } from 'redux';
import { reducer as layout } from 'containers/Layout';
import { reducer as entities } from 'infrastructure/domain';
import { reducer as config } from 'infrastructure/config';
import { reducer as storyEditor } from 'infrastructure/storyEditor';
import { reducer as uploads } from 'infrastructure/uploads';
import account from 'slices/Account/reducer';
import dataTable from '../../components/DataTable/reducer';

export default function createReducer(injectedReducers) {
  return combineReducers({
    layout,
    config,
    entities,
    dataTable,
    account,
    storyEditor,
    uploads,
    ...injectedReducers,
  });
}
