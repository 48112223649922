import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderComponent, lifecycle } from 'recompose';
import { withRouter } from 'react-router';

import { Loading } from '@stormideas/react-sdk';

const mapStateToProps = ({ account }) => ({
  account,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  branch(
    p => !p.account || !p.account.authenticated,
    renderComponent(
      lifecycle({
        componentDidMount() {
          this.props.history.push(
            `/login?returnUrl=${this.props.location.pathname}`
          );
        },
      })(() => <Loading visible />)
    )
  )
);

const Secured = ({ children }) => React.Children.only(children);

export default enhance(Secured);
