import React from 'react';
import './Activity.scss';
import PropTypes from 'prop-types';

/** For use in a feed of actions, for example changes to a story */

const Activity = props => {
  const { action, imgSrc, moreInfo, name, timestamp, button } = props;
  return (
    <div className="activity">
      {imgSrc && (
        <img src={imgSrc} alt={name} className="thumbnail rounded-pill" />
      )}
      <div className="details">
        <div className="d-flex justify-content-between align-content-baseline">
          <div>
            {name && <h4 className="name">{name}</h4>}
            {action && <span className="action"> {action}</span>}
            {timestamp && <span className="timestamp">{timestamp}</span>}
          </div>
          <div>{button && button}</div>
        </div>
        {moreInfo && <p className="more-info">{moreInfo}</p>}
      </div>
    </div>
  );
};

Activity.propTypes = {
  /** Action that the user took, eg. Updated a Story */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Link to user image */
  imgSrc: PropTypes.string,
  /** Description of the action */
  moreInfo: PropTypes.node,
  /** User name */
  name: PropTypes.string,
  /** eg. "Yesterday at 4pm" or a RelativeTime component */
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  button: PropTypes.node,
};

Activity.defaultProps = {
  action: null,
  imgSrc: null,
  moreInfo: null,
  name: null,
  timestamp: null,
  button: null,
};

export default Activity;
